@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .jobDetails {
    grid-template-columns:
      [details] repeat(5, minmax(0, 1fr)) [tracking] repeat(4, minmax(0, 1fr))
      [actions] repeat(3, minmax(0, 1fr));
  }

  .jobListCard {
    grid-template-columns:
      [jobType] repeat(3, minmax(0, 1fr)) [brand] 1fr [address] repeat(
        6,
        minmax(0, 1fr)
      )
      [status] 7rem [action] 1fr;
  }

  .bookJob {
    grid-template-columns:
      [calendar] repeat(8, minmax(0, 1fr))
      [side] repeat(4, minmax(0, 1fr));
    grid-template-rows: max-content 240px 1fr auto;
  }

  .quoteScreen {
    grid-template-columns: [quote-details] repeat(8, minmax(0, 1fr)) [whitespace] repeat(
        4,
        minmax(0, 1fr)
      );
    grid-template-rows: minmax(5rem, auto) 1fr;
  }
}

@layer utilities {
  /*This utility is to be used when the native scrollbar on Mac OS is interfering with the content, such as rendering on top
    or messing with the padding
  */
  .scrollbar {
    &::-webkit-scrollbar {
      width: 6px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

@layer base {
  h1 {
    @apply text-2xl font-semibold;
  }
  input[type='number'] {
    border-width: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.overlay {
  @apply bg-blue-darkest;
  z-index: 45;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: pointer !important;
}
