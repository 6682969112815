.success {
  @apply bg-green-lightest dark:bg-green-lightest
}

.error {
  @apply bg-red-lightest dark:bg-red-lightest
}

.warning {
  @apply bg-orange-lightest dark:bg-orange-lightest
}

.alertContainer {
  @apply p-4 mb-4 z-50 
  flex items-center p-4 mb-4 rounded gap-x-3 grid grid-cols-2; 
  grid-template-columns: min-content auto;
	animation: toast-in-right .8s;
}

.alertsContainer {
  @apply z-50 box-border fixed top-5 right-4 min-w-[90%] max-w-[90%] md:min-w-[24rem] md:max-w-[44rem];
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

