.small {
  @apply bg-white rounded-lg p-4 w-80 justify-center items-center flex flex-col gap-y-4 z-50;
}

.dismissButton {
  @apply m-2 p-2 absolute top-0 right-0 rounded-lg hover:bg-cta-hover;
}

.iconBackground {
  @apply bg-background rounded-lg p-3;
}

.title {
  @apply text-lg font-semibold text-typography;
}

.subTitle {
  @apply text-typography-secondary;
}